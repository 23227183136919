import { Select, Table } from 'antd'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import cookies from 'react-cookies'
import { strapiURL } from '../../../config'
import { useSelector } from 'react-redux'
import ApplyJobModal from '../../Modals/jobApply'
import ApplySuccessModal from '../../Modals/applySuccessModal'
import { Link } from 'gatsby'
import moment from 'moment'
import {
  permissionsHandler,
  restructureJob,
  dateFormatHandler,
} from '../../../functions'

const YourJobsComponent = ({ userProfile }) => {
  const { Option } = Select
  const [loading, setLoading] = useState(true)
  const [sortValue, setSortValue] = useState('title')
  const [yourJobs, setYourJobs] = useState([])
  const [applyJob, setApplyJob] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  let user = cookies.load('user')

  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  let isAllowed = products?.subscriptionPurchased

  const getYourJobs = async page => {
    try {
      setLoading(true)
      setYourJobs([])
      const { data } = await axios.get(
        strapiURL +
          `/candidate-profiles/${userProfile?.id}/getSavedJobs?page=${page}&sortBy=${sortValue}`
      )
      let _savedJobs = data?.savedJobs
      setYourJobs(_savedJobs || [])
      setTotal(data?.total || 0)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getYourJobs(page)
  }, [page])

  useEffect(() => {
    setPage(1)
    getYourJobs(1)
  }, [sortValue])

  const onChangePage = page => {
    setPage(page)
  }

  const yourJobCols = [
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div className="flex">
            {user && isAllowed && (
              <div className="h-10 w-10 rounded shadow flex items-center justify-center">
                {_job?.confidential ? (
                  <div
                    className="h-10 w-10 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${'/images/confident-logo.png'})`,
                    }}
                  />
                ) : (
                  <div
                    className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${
                        _job?.employerDetails?.image?.url ||
                        '/icons/icon-medical.png'
                      } )`,
                    }}
                  />
                )}
              </div>
            )}
            <div className="ml-2">
              <p className="text-xs font-bold font-sans mb-0.5">
                {_job?.title || ''}
              </p>
              <div className="flex items-center ">
                <img src="/icons/icon-location-pin.svg" className="mb-0" />
                <span className="text-[13px] text-location leading-0 ml-1">
                  {_job.location}
                </span>
                {record?.issuggested ? (
                  <span className="text-[9px] mb-0 ml-1 bg-voyage text-white font-sans px-2 rounded-full h-4 flex items-center">
                    Suggested
                  </span>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        )
      },
    },
    ...(user && isAllowed
      ? [
          {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text, record, index) => {
              let _job = restructureJob(record)
              if (_job?.confidential) {
                return null
              } else {
                return (
                  <div>
                    <p className="text-location text-last font-sans mb-0">
                      Company Name
                    </p>
                    <p className="text-last font-sans mb-0">{_job.employer}</p>
                  </div>
                )
              }
            },
          },
        ]
      : []),
    {
      title: 'Compensation Type',
      dataIndex: 'compensation_type',
      key: 'compensation_type',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              Compensation Type
            </p>
            <p className="text-last font-sans mb-0">{_job.compensation_type}</p>
          </div>
        )
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'exp',
      key: 'exp',
      render: (text, record, index) => {
        const expiry = moment(record.published_at).add(30, 'days').toDate()
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              Expiry Date
            </p>
            <p className="text-last font-sans mb-0">
              {record.archived_date
                ? dateFormatHandler(record.archived_date)
                : dateFormatHandler(expiry)}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        const externalApply = applicationUrl => {
          // Open job in new link
          window.open(applicationUrl, '_blank')
          // Submit application against that job
          var bodyFormData = new FormData()
          let data = {
            job: parseInt(_job.id),
            candidate_profile: userProfile?.id,
            appliedAt: new Date(),
            status: 'Active',
            cover_letter_text: 'External Job',
          }
          bodyFormData.append('data', JSON.stringify(data))
          axios({
            method: 'post',
            url: strapiURL + `/applications`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
          }).catch(err => {
            console.log(err)
          })
          // Update apply count for that job
          axios
            .put(strapiURL + `/jobs/applyCount/${_job.slug}`)
            .then(res => {
              window.location.reload(false)
            })
            .catch(err => {
              console.log(err)
            })
        }
        return (
          <>
            {record?.applied ? (
              <Link to={`/job-detail/${_job.slug}`}>
                <button className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2">
                  Applied
                </button>
              </Link>
            ) : (
              <>
                {products?.subscriptionPurchased ? (
                  _job?.application_method === 'externalRedirect' ? (
                    <button
                      onClick={() => {
                        externalApply(_job.applicationUrl)
                      }}
                      className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2"
                    >
                      Apply Now
                    </button>
                  ) : (
                    <button
                      onClick={event => {
                        setApplyJob(_job)
                        event.stopPropagation()
                      }}
                      className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2"
                    >
                      Apply Now
                    </button>
                  )
                ) : (
                  <Link to={'/member-subscription/subscription'}>
                    <button className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2">
                      Apply Now
                    </button>
                  </Link>
                )}
              </>
            )}
          </>
        )
      },
    },
  ]

  return (
    <div>
      <div className="container mx-auto">
        <div className="px-6 lg:px-0 rounded-lg bg-white mt-4">
          <div className="flex py-4 border-b border-bgSecondaryButton justify-between">
            <div className="flex items-center">
              <img src="/icons/icon-baksa.svg" className="mb-0" />
              <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                Your Jobs
              </h1>
            </div>
            <div className="hidden lg:flex items-center">
              <div className="relative border border-[#C4C4C4] rounded focus:border-merlot px-2 py-1 sort-select sort-by-text">
                <span className="absolute z-10 font-sans font-semibold text-[#6A6A6A] mt-1">
                  Sort By:
                </span>
                <Select
                  disabled={!yourJobs?.length}
                  defaultValue={sortValue}
                  onChange={val => setSortValue(val)}
                  className="bg-transparent z-20 relative"
                >
                  <Option value="title">Title</Option>
                  <Option value="published_at">Expiry Date</Option>
                  <Option value="compensation_type">Compensation Type</Option>
                  <Option value="primary_function">Function</Option>
                </Select>
              </div>
            </div>
          </div>
          {loading ? (
            <div
              className="w-full min-h-700px bg-no-repeat bg-cover"
              style={{
                backgroundImage: `url(/loaders/application-history-loader.png)`,
              }}
            />
          ) : (
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    window.open('/job-detail/' + record?.slug)
                  },
                }
              }}
              className="hide-table-header-background hide-table-header-cols-border table-with-pagination cursor-pointer"
              columns={yourJobCols}
              dataSource={yourJobs}
              bordered={false}
              pagination={{
                current: page,
                onChange: onChangePage,
                total,
              }}
            />
          )}
        </div>
        <ApplyJobModal
          currentJob={applyJob}
          setApplyJob={setApplyJob}
          userProfile={userProfile}
          setSuccessModal={setSuccessModal}
        />
        <ApplySuccessModal modalOpen={successModal} />
      </div>
    </div>
  )
}
export default YourJobsComponent
